import { generateMapByOpts } from 'common/utils'
const typeOps = [
  {
    text: '全部',
    value: null
  },
  {
    text: '异常',
    value: 0
  },
  {
    text: '蓝牙',
    value: 1
  },
  {
    text: '视频',
    value: 2
  },
  {
    text: '二维码',
    value: 3
  },
  {
    text: 'NFC',
    value: 4
  }
]
const statusOps = [
  {
    text: '全部',
    value: null
  }, {
    text: '未巡',
    value: 0
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '异常',
    value: 2
  },
  {
    text: '漏巡',
    value: 3
  }
]
const bluetoothStatusOps = [
  {
    text: '关闭',
    value: 0
  },
  {
    text: '开启',
    value: 1
  }
]
// 订单状态
const orderStatusOps = [
  {
    text: '待接收',
    value: 1
  },
  {
    text: '处理中',
    value: 2
  },
  {
    text: '已处理',
    value: 3
  },
  {
    text: '已评价',
    value: 4
  },
  {
    text: '已关闭',
    value: 5
  },
  {
    text: '待处理',
    value: 6
  }
]

// 新订单状态
const newOrderStatusOps = [
  {
    text: '待接收',
    value: 1
  },
  {
    text: '待处理',
    value: 2
  },
  {
    text: '超时未接收',
    value: 3
  },
  {
    text: '超时未处理',
    value: 4
  },
  {
    text: '处理中',
    value: 5
  },
  {
    text: '已处理',
    value: 6
  },
  {
    text: '已完成',
    value: 7
  },
  {
    text: '已关闭',
    value: 8
  },
  {
    text: '超时处理中',
    value: 9
  },
]

const typeMap = generateMapByOpts(typeOps)
const statusMap = generateMapByOpts(statusOps)
const bluetoothStatusMap = generateMapByOpts(bluetoothStatusOps)
const orderStatusMap = generateMapByOpts(orderStatusOps)
const newOrderStatusMap = generateMapByOpts(newOrderStatusOps)
export {
  typeOps,
  typeMap,
  statusOps,
  statusMap,
  bluetoothStatusMap,
  orderStatusMap,
  newOrderStatusMap
}
