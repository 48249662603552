var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "patrolSign-container" },
    [
      _c(
        "form-panel",
        { ref: "formPanel", attrs: { form: _vm.form } },
        [
          _c("div", { staticClass: "cell-title" }, [
            _c("span", { staticClass: "pre-point" }),
            _vm._v("巡更点信息:"),
          ]),
          _c("el-form-item", { attrs: { label: "巡更点名称" } }, [
            _c("span", [_vm._v(_vm._s(_vm.form.pointName))]),
          ]),
          _c("el-form-item", { attrs: { label: "所属巡更路线" } }, [
            _c("span", [_vm._v(_vm._s(_vm.form.routeName))]),
          ]),
          _c("el-form-item", { attrs: { label: "巡更人员" } }, [
            _c("span", [_vm._v(_vm._s(_vm.form.dealUserName))]),
          ]),
          _c("el-form-item", { attrs: { label: "巡更方式" } }, [
            _c("span", [_vm._v(_vm._s(_vm.typeMap[_vm.form.type]))]),
          ]),
          _c("el-form-item", { attrs: { label: "巡更点状态" } }, [
            _c("span", [_vm._v(_vm._s(_vm.statusMap[_vm.form.appStatus]))]),
          ]),
          _c("el-form-item", { attrs: { label: "巡更内容" } }, [
            _c(
              "ul",
              _vm._l(_vm.form.patrolContents, function (content, index) {
                return _c("li", { key: index }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(index + 1) +
                      "、" +
                      _vm._s(content.content) +
                      "\n          "
                  ),
                  !content.normal
                    ? _c(
                        "div",
                        { staticStyle: { "margin-left": "10px" } },
                        _vm._l(content.reportList, function (order, idx) {
                          return _c(
                            "span",
                            { key: idx },
                            [
                              _vm._v("\n            巡更报事信息：工单编号 "),
                              _c("v-button", {
                                attrs: {
                                  type: "text",
                                  text: order.serialNumber,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.orderClick(order)
                                  },
                                },
                              }),
                              _vm.form.newOrder
                                ? [
                                    _vm._v(
                                      "\n              (" +
                                        _vm._s(
                                          _vm.newOrderStatusMap[
                                            order.orderStatus
                                          ]
                                        ) +
                                        ")\n            "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      "\n              (" +
                                        _vm._s(
                                          _vm.orderStatusMap[order.orderStatus]
                                        ) +
                                        ")\n            "
                                    ),
                                  ],
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isQrcodeStatus,
                  expression: "!isQrcodeStatus",
                },
              ],
              attrs: { label: "设备编号" },
            },
            [_c("span", [_vm._v(_vm._s(_vm.form.bluetoothNum))])]
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isQrcodeStatus,
                  expression: "!isQrcodeStatus",
                },
              ],
              attrs: { label: "设备位置" },
            },
            [_c("span", [_vm._v(_vm._s(_vm.form.bluetoothLocation))])]
          ),
          _c("el-form-item", { attrs: { label: "设备状态" } }, [
            _c("span", [
              _vm._v(_vm._s(_vm.bluetoothStatusMap[_vm.form.bluetoothStatus])),
            ]),
          ]),
          _c("el-form-item", { attrs: { label: "所属项目" } }, [
            _c("span", [_vm._v(_vm._s(_vm.form.communityName))]),
          ]),
          _c("el-form-item", { attrs: { label: "所属组团" } }, [
            _c("span", [_vm._v(_vm._s(_vm.form.blockname))]),
          ]),
          _c("el-form-item", { attrs: { label: "巡更点一级分类" } }, [
            _c("span", [_vm._v(_vm._s(_vm.form.firstLevelName))]),
          ]),
          _c("el-form-item", { attrs: { label: "巡更点二级分类" } }, [
            _c("span", [_vm._v(_vm._s(_vm.form.secondLevelName))]),
          ]),
          _vm.isQrcodeStatus
            ? [
                _c("el-form-item", { attrs: { label: "定位地点" } }, [
                  _c("span", [_vm._v(_vm._s(_vm.form.address))]),
                ]),
                _vm.pirtureImg && _vm.pirtureImg.length
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "上传照片" } },
                      [_c("v-preview", { attrs: { imgs: _vm.pirtureImg } })],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }