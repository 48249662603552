<template>
  <div class="patrolSign-container">
    <form-panel ref="formPanel" :form="form" >
      <div class="cell-title">
        <span class="pre-point"></span>巡更点信息:</div>
      <el-form-item label="巡更点名称">
        <span >{{ form.pointName }}</span>
      </el-form-item>
      <el-form-item label="所属巡更路线" >
        <span >{{ form.routeName }}</span>
      </el-form-item>
      <el-form-item label="巡更人员" >
        <span >{{ form.dealUserName }}</span>
      </el-form-item>
      <el-form-item label="巡更方式" >
        <span >{{ typeMap[form.type] }}</span>
      </el-form-item>
      <el-form-item label="巡更点状态" >
        <span >{{ statusMap[form.appStatus] }}</span>
      </el-form-item>
      <el-form-item label="巡更内容" >
        <ul>
          <li v-for="(content,index) in form.patrolContents" :key="index">
            {{ index+1 }}、{{ content.content }}
            <div v-if="!content.normal" style="margin-left:10px;">
              <span v-for="(order,idx) in content.reportList" :key="idx">
              巡更报事信息：工单编号 <v-button type="text" :text="order.serialNumber" @click="orderClick(order)"></v-button>
              <template v-if="form.newOrder">
                ({{newOrderStatusMap[order.orderStatus]}})
              </template>
              <template v-else>
                ({{orderStatusMap[order.orderStatus]}})
              </template>
              </span>
            </div>
          </li>
        </ul>
      </el-form-item>
      <el-form-item label="设备编号"  v-show="!isQrcodeStatus">
        <span >{{ form.bluetoothNum }}</span>
      </el-form-item>
      <el-form-item label="设备位置"  v-show="!isQrcodeStatus">
        <span >{{ form.bluetoothLocation }}</span>
      </el-form-item>
      <el-form-item label="设备状态" >
        <span >{{ bluetoothStatusMap[form.bluetoothStatus] }}</span>
      </el-form-item>
      <el-form-item label="所属项目" >
        <span >{{ form.communityName }}</span>
      </el-form-item>
      <el-form-item label="所属组团" >
        <span >{{ form.blockname }}</span>
      </el-form-item>
      <el-form-item label="巡更点一级分类" >
        <span >{{ form.firstLevelName }}</span>
      </el-form-item>
      <el-form-item label="巡更点二级分类" >
        <span >{{ form.secondLevelName }}</span>
      </el-form-item>
      <!-- TODO 待修改 -->
      <template v-if="isQrcodeStatus">
        <el-form-item label="定位地点">
          <span >{{ form.address }}</span>
        </el-form-item>
        <el-form-item label="上传照片" v-if="pirtureImg && pirtureImg.length">
          <v-preview
            :imgs="pirtureImg"
          ></v-preview>
        </el-form-item>
      </template>
    </form-panel>
  </div>
</template>

<script>
import { getPatrolLogURL, checkIsOldOrder } from './api'
import { typeMap, statusMap, bluetoothStatusMap, orderStatusMap, newOrderStatusMap } from './map'
import { vPreview } from 'components/control'
export default {
  name: 'patrolLog',
  components: {
    vPreview,
  },
  computed: {
    isQrcodeStatus () {
      return this.form.type == 3
    },
    pirtureImg () {
      let { pirtureUrl } = this.form;
      let arr = [];
      if (pirtureUrl){
        arr.push({
          src: pirtureUrl
        })
      }
      return arr
    }
  },
  data () {
    return {
      form: {
        pointName: '',
        routeName: '',
        dealUserName: '',
        type: '',
        status: '',
        patrolContents: [],
        bluetoothNum: '',
        bluetoothLocation: '',
        bluetoothStatus: '',
        communityName: '',
        blockname: '',
        firstLevelName: '',
        secondLevelName: '',
        pirtureUrl: undefined,
      },
      typeMap: typeMap,
      statusMap: statusMap,
      bluetoothStatusMap: bluetoothStatusMap,
      orderStatusMap: orderStatusMap,
      newOrderStatusMap
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getDetailData(this.$route.query.id)
    }
  },
  methods: {
    // 获取详情数据
    getDetailData (id) {
      let _this_ = this
      this.$axios.get(getPatrolLogURL + id).then(res => {
        if (res.status === 100) {
          let rdata = res.data
          if (rdata) {
            _this_.form = Object.assign(_this_.form, rdata)
            if (rdata.patrolContents.length) {
              // let contents = []
              // rdata.patrolContents.forEach(item => {

              // })
            }
          }
        }
      })
    },
    
    // 跳转到工单页面
    async orderClick (order) {
      // const { data } = await this.checkIsOldOrder(order.serialNumber)
      // 跳转工单3.0
      if (this.form.newOrderV3) {
        const menuInfo = this.$store.getters['menuStore/getMenuInfo'](947241)
        this.$router.push({
          name:'iframePanel',
          query:{
            pid:1,
            cid:3400,
            fid:94751,
            sid:947241,
            src: menuInfo.item.url,
            orderId: order.orderId,
            isReplace: 1
          }
        })
      } else {
        if(this.form.newOrder) {
          // 新工单
          this.$router.push({
            name: 'orderSchedulingForm',
            query: {
              id: order.orderId,
            }
          })
        }else {
          // 老工单
          this.$router.push({
            name: 'serviceSheetForm',
            query: {
              id: order.orderId,
              orderCode: order.serialNumber,
              communityId: this.form.communityId
            }
          })
        }
      }
      
    },
    checkIsOldOrder(id) {
      return this.$axios.get(checkIsOldOrder, {
        params: {
          serialNumber: id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.patrolSign-container {
    .cell-title {
    text-align: left;
    padding-left: 10px;
    margin: 15px 0;
    display: flex;
    align-items: center;
   .pre-point {
      // display: inline-block;
      border-radius: 50%;
      background-color: #dce2e7;
      height: 8px;
      width: 8px;
      margin-right: 5px;
    }
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .contentList{
    .contengLine {
      margin-right:10px;
    }
    .addBtn{
      margin-left: 140px;
    }
  }
}
</style>
